<template>
  <div id="container_wrapper">
    <div id="container">
      <div id="close-top">
        <a class="link" href="javascript:self.close();" title="Close"
        >Close this window</a
        >
      </div>
      <br />
      <div id="header"></div>
      <div id="info">
        <h1>DRT Debt Reduction Team Privacy Policy</h1>
        <h2>Last updated: FEB 01, 2020</h2>
        Debt Reduction Team (the "Website," "we," "our," or "us") takes your
        privacy seriously. Please read the following to learn more about our
        privacy policy. By visiting this website, you are accepting the practices
        described in this Privacy Notice. What This Privacy Policy Covers<br /><br />
        <ul>
          <li>
          <span class="text"
          >This policy covers how we treat personal information that the
            website collects and receives, including information related to
            you're past use of our products and services. Personal information
            is information about you that is personally identifiable like your
            name, address, email address, IP address, or phone number, and that
            is not otherwise publicly available.
          </span>
          </li>
          <li>
          <span class="text"
          >This policy does not apply to the practices of companies that we
            does not own or control, or to people that we do not employ or
            manage.
          </span>
          </li>
        </ul>
        <br /><b>Information Collection and Use </b><br /><b>General </b><br />

        <ul>
          <li>
          <span class="text"
          >The website collects personal information when you register with
            us. We may combine information about you that we have with
            information we obtain from business partners or other companies.
          </span>
          </li>
          <li>
          <span class="text"
          >When you register we ask for information such as your name,
            address, phone number, email address and zip code. Once you register
            with us and sign in to our services, you are not anonymous to us.
          </span>
          </li>
          <li>
          <span class="text"
          >We collect information about your transactions with us and with
            some of our business partners, including information about your use
            of financial products and services that we offer.
          </span>
          </li>
          <li>
          <span class="text"
          >The website automatically receives and records information on our
            server logs from your browser, including your IP address, cookie
            information, and the page you request.
          </span>
          </li>
          <li>
          <span class="text"
          >We use information for the following general purposes: to fulfill
            your requests for products and services, improve our services,
            contact you, conduct research, and provide anonymous reporting for
            internal and external clients.
          </span>
          </li>
          <li>
          <span class="text"
          >We reserve the right to send you certain communications relating to
            our services, such as service announcements, administrative
            messages, special offers and an website Newsletter.</span
          >
          </li>
        </ul>
        <b>Confidentiality and Security </b>
        <ul>
          <li>
          <span class="text"
          >We limit access to personal information about you to employees who
            we believe reasonably need to come into contact with that
            information to provide products or services to you or in order to do
            their jobs.</span
          >
          </li>
          <li>
          <span class="text"
          >We have physical, electronic, and procedural safeguards that comply
            with federal regulations to protect personal information about
            you.</span
          >
          </li>
        </ul>
        <b>Cookies</b>
        <ul>
          <li>
          <span class="text"
          >We may set and access cookies on your computer. We use this
            information for Web Site and Systems Administration.
          </span>
          </li>
          <li>
          <span class="text"
          >Cookies are alphanumeric identifiers that we transfer to your
            computer's hard drive through your Web browser to enable our systems
            to recognize your browser and to provide features and storage of
            items in your Shopping Cart between visits.</span
          >
          </li>
        </ul>
        <b>Information Sharing and Disclosure</b>
        <ul>
          <li>
          <span class="text"
          >Information You Give Us: We receive and store any information you
            enter on our Web site or give us in any other way. You can choose
            not to provide certain information, but then you might not be able
            to take advantage of many of our features. We use the information
            that you provide for such purposes as responding to your requests,
            customizing future shopping for you, improving our stores, and
            communicating with you.
          </span>
          </li>
          <li>
          <span class="text"
          >Automatic Information: We receive and store certain types of
            information whenever you interact with us. For example, like many
            Web sites, we use "cookies," and we obtain certain types of
            information when your Web browser accesses this site.
          </span>
          </li>
          <li>
          <span class="text"
          >Agents: We employ other companies and individuals to perform
            functions on our behalf. Examples include fulfilling orders,
            delivering packages, sending postal mail and e-mail, removing
            repetitive information from customer lists, analyzing data,
            providing marketing assistance, providing search results and links
            (including paid listings and links), processing credit card
            payments, and providing customer service. They have access to
            personal information needed to perform their functions, but may not
            use it for other purposes
          </span>
          </li>
          <li>
          <span class="text"
          >We may share personal information about you with trusted partners
            who work on behalf of or with us. These companies may use your
            personal information to help us communicate with you about offers
            from the website and our marketing partners. You may receive certain
            communications relating to our services, such as service
            announcements, administrative messages, special offers and a
            Newsletter.</span
          >
          </li>
          <li>
          <span class="text"
          >We may provide personal contact information about you with our
            trusted partners who work on behalf of or with us. These companies
            may use your personal information to help us communicate with you
            about offers from us and our marketing partners.</span
          >
          </li>
          <li>
          <span class="text"
          >We respond to subpoenas, court orders, or legal process, or to
            establish or exercise our legal rights or defend against legal
            claims
          </span>
          </li>
          <li>
          <span class="text"
          >We believe it is necessary to share information in order to
            investigate, prevent, or take action regarding illegal activities,
            suspected fraud, violations of our terms of use, or as otherwise
            required by law.
          </span>
          </li>
          <li>
          <span class="text"
          >We transfer information about you if we are acquired by or merged
            with another company. In this event, we will notify you before
            information about you is transferred and becomes subject to a
            different privacy policy.</span
          >
          </li>
        </ul>
        <b>Changes to this Privacy Policy</b>
        <ul>
          <li>
          <span class="text"
          >We may update this policy. We will notify you about significant
            changes in the way we treat personal information by sending a notice
            to the primary email you entered or by placing a prominent notice on
            our site.
          </span>
          </li>
        </ul>

        <b>Email Policy</b>
        <ul>
          <li>
          <span class="text">
            To opt-out from receiving any additional email communications
            regarding this website, write to us at:
            <a href="mailto:info@debtreductionteam.com"
            >info@debtreductionteam.com</a
            >
          </span>
          </li>
        </ul>

        <b>Wireless Policy</b>
        <ul>
          <li>
          <span class="text">
            We may use personal information to provide the services you've
            requested, including services that display customized content and
            advertising. In addition to any fee of which you are notified, your
            provider's standard messaging rates apply to our confirmation and
            all subsequent SMS correspondence. You may opt-out and remove your
            SMS information by sending "STOP", "END", "QUIT" to the SMS text
            message you have received. If you remove your SMS information from
            our database it will no longer be used by us for secondary purposes,
            disclosed to third parties, or used by us or third parties to send
            promotional correspondence to you.
          </span>
          </li>
        </ul>

        <b>Detailed Wireless Policy</b>
        <ul>
          <li>
          <span class="text">
            Data obtained from you in connection with this SMS service may
            include your name, address, cell phone number, your provider's name,
            and the date, time, and content of your messages. In addition to any
            fee of which you are notified, your provider's standard messaging
            rates apply to our confirmation and all subsequent SMS
            correspondence. All charges are billed by and payable to your mobile
            service provider. We will not be liable for any delays in the
            receipt of any SMS messages, as delivery is subject to effective
            transmission from your network operator. SMS message services are
            provided on an AS IS basis.<br /><br />
            We may use personal information to provide the services you've
            requested, including services that display customized content and
            advertising. We may also use personal information for auditing,
            research and analysis to operate and improve our technologies and
            services. We may share aggregated and non personal information with
            third parties. When we use third parties to assist us in processing
            your personal information, we require that they comply with our
            Privacy Policy and any other appropriate confidentiality and
            security measures. We may also share information with third parties
            in limited circumstances, including when complying with legal
            process, preventing fraud or imminent harm, and ensuring the
            security of our network and services. <br /><br />
            You may remove your information from our database. If you remove
            your information from our database it will no longer be used by us
            for secondary purposes, disclosed to third parties, or used by us or
            third parties to send promotional correspondence to you. You may
            remove your information by sending "STOP", "END", "QUIT" to the SMS
            text message you have received.
          </span>
          </li>
        </ul>
      </div>
      <div id="close-bottom">
        <a class="link" href="javascript:self.close();" title="Close"
        >Close this window</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped lang="scss">
  #container_wrapper {
    padding: 0 20px 10px 20px;
    background-color: #ffffff;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    font-family: Arial, Sans, Helvitica, sans-serif;
    font-style: normal;
    color: #333333;
    #container {
      background-color: #FFFFFF;
      position: relative;
      margin: 0 auto;
      overflow: auto;
      width: 1000px;
      #close-top {
        float: right;
        padding: 5px 50px 0 0;
        .link {
          font-size: 11px;
          font-weight: normal;
        }
      }
      #header {
        text-align: left;
        padding: 0 0 10px 100px;
        border-bottom: 1px solid #cccccc;
      }
      #info {
        padding: 20px 100px 0 100px;
        h1 {
          font-size: 23px;
          font-weight: normal;
        }
        h2 {
          font-size: 12px;
          font-weight: normal;
          color: #999999;
        }
        ul li {
          font-size: 12px;
          margin: 0;
        }
      }
      #close-bottom {
        float: right;
        padding: 0 50px 5px 0;
      }
    }
  }
</style>